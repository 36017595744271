define("discourse/plugins/timeline/discourse/pre-initializers/timeline", ["exports", "ember-addons/ember-computed-decorators", "discourse/components/topic-list-item"], function (_exports, _emberComputedDecorators, _topicListItem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: 'timeline',
    before: 'inject-discourse-objects',
    initialize() {
      _topicListItem.default.reopen(dt7948.p({
        unboundClassNames(topic, lastVisitedTopic) {
          let classes = [];
          if (topic.get('unread_post')) {
            classes.push('unread');
          }
          if (topic.get('category')) {
            classes.push("category-" + topic.get('category.fullSlug'));
          }
          if (topic.get('hasExcerpt')) {
            classes.push('has-excerpt');
          }
          ['liked', 'archived', 'bookmarked'].forEach((name, index) => {
            if (topic.get(name)) {
              classes.push(name);
            }
          });
          if (topic === lastVisitedTopic) {
            classes.push('last-visit');
          }
          return classes.join(' ');
        }
      }, [["method", "unboundClassNames", [(0, _emberComputedDecorators.default)('topic', 'lastVisitedTopic')]]]));
    }
  };
});